import React from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import { useEffect, useState } from "react";
import Loading from "../../components/loading/Loading";

export default function TeamCards({ base, json, equipeInclude }) {
  const [equipes, setEquipes] = useState([]);

  var loaded = false;

  useEffect(() => {
    if (loaded === false) {
      loaded = true;
      base("Equipes")
        .select({
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              if (record.get("projet").includes(equipeInclude)) {
                var element = {
                  titre:
                    json.langue === "fr"
                      ? record.get("titre")
                      : record.get("titre_en"),
                  classe: record.get("classe"),
                  url: record.get("url"),
                  image:
                    record.get("image") === undefined
                      ? ""
                      : record.get("image")[0].url,
                  contenu:
                    json.langue === "fr"
                      ? record.get("contenu")
                      : record.get("contenu_en"),
                };
                setEquipes((equipes) => [...equipes, element]);
              }
            });
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return;
            }
          }
        );
    }
  }, []);

  return (
    <div className="equipes">
      {equipes && equipes.length > 0 ? (
        equipes.map((equipe) => (
          <Link to={"/equipe/" + equipe.url}>
            <div className="card-simple card-white">
              {/* <img src={fs} className="card-icon" alt="logo-nantes" /> */}
              <img src={equipe.image} alt={equipe.titre} />
              <div className="card-simple-ctn">
                <div className="title">{equipe.titre}</div>
                {equipe.contenu}
              </div>
            </div>

            {/* 
            <div className="equipe">
              <div className={"equipe-img " + equipe.classe}></div>
              <div className="equipe-ctn">
                <div className="equipe-title border-left-red">
                  {json.team} {equipe.titre}
                </div>
                {equipe.contenu}
              </div>
            </div>
             */}
          </Link>
        ))
      ) : (
        <Loading />
      )}
    </div>
  );
}
