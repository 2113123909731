import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import Loading from "../../components/loading/Loading";

export default function CompetCards({ base, json }) {
  const [competes, setCompetes] = useState([]);

  var loaded = false;
  useEffect(() => {
    if (loaded === false) {
      loaded = true;

      base("competitions")
        .select({
          sort: [{ field: "titre", direction: "asc" }],
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              if (record.get("projet").includes("pe")) {
                var element = {
                  titre: record.get("titre"),
                  classe: record.get("classe"),
                  url: record.get("url"),
                  image:
                    record.get("image") === undefined
                      ? ""
                      : record.get("image")[0].url,
                  contenu:
                    json.langue === "fr"
                      ? record.get("contenu")
                      : record.get("contenu_en"),
                };
                setCompetes((competes) => [...competes, element]);
              }
            });
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return;
            }
          }
        );
    }
  }, []);

  return (
    <div className="equipes">
      {competes ? (
        competes.map((compet) => (
          <a href={compet.url} target="_blank">
            <div className="card-simple card-red">
              {/* <img src={fs} className="card-icon" alt="logo-nantes" /> */}
              <img src={compet.image} alt={compet.titre} />
              <div className="card-simple-ctn">
                <div className="title">{compet.titre}</div>
                {compet.contenu}
              </div>
            </div>
          </a>
        ))
      ) : (
        <Loading />
      )}
    </div>
  );
}
