import React, { useEffect, useState } from "react";
import "./Equipe.css";
import "../../App.css";
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import pe_las from "../../images/las.jpg";
import fs_las from "../../images/fs_las.jpg";
import fs_aero from "../../images/fs_aero.jpg";
import fs_fab from "../../images/fs_fab.jpg";
import fab from "../../images/fab.jpg";
import se from "../../images/se.jpg";
import part from "../../images/reunion-partenaires.jpg";
import stig from "../../images/membres/stig.png";
import error_404 from "../../images/404.jpg";
import Loading from "../../components/loading/Loading";
import { useNavigate } from "react-router-dom";
import { localStorageLanguageSuffix } from "../../components/api/BaseSelect";

// select().all()
/*
Record
  fields: 
    Administrative_Team: (2) ['Bureau', 'Communication']
    Annee: "A4"
    Calculation: "CHOUKROUN\n Nathan"
    Description: "Directeur des **systemes embarqués **\n"
    Ecole: "ESILV"
    Email: "nathan.choukroun@edu.devinci.fr"
    Etranger: "Non"
    Nom: "CHOUKROUN\n"
    Prenom: "Nathan"
    Statut: "DT"
    Technical_Team: "Systèmes embarqués"
    image: Array(1)
      0: 
      filename: "Nathan Choukroun.jpg"
      height: 1800
      id: "att7GKajFQwfbUAAQ"
      size: 173733
      thumbnails: 
        full: {url: 'https://v5.airtableusercontent.com/v3/u/27/27/1712…73EmA/2O27KxGj0NMe2QszndgV6tMkZSSY2ktVZuenAI1QCvM', width: 1200, height: 1800}
        large: {url: 'https://v5.airtableusercontent.com/v3/u/27/27/1712…JgO6w/jOSZb9WeDs9lAXwUllG5AckVFJfNtJn7sfe6-bAJV_E', width: 512, height: 768}
        small: {url: 'https://v5.airtableusercontent.com/v3/u/27/27/1712…IyWtw/WlRtOaUFnKveryWzl8kvBGxuTg429SGjZOv4Caxy6GE', width: 24, height: 36}
      type: "image/jpeg"
      url: "https://v5.airtableusercontent.com/v3/u/27/27/1712340000000/_1nFcayb6XmNudjgfET1iQ/T8VfTtC4_zW2Pjr8ZBI5_NqrFXk-qJ4XMHDWpFLWjVkZVfte2I8u-1_l2Zpzmdf_ch9wYlZgrpcjNvmkIrNCus_SCmtZ4qufZ6YcUsWiNcOQKtLClf5Vu3nvT9GcITulCgp-ELqleSuYZaruRb9PSA/SKEaTcVsUReWC3FRz5W9BRG1lczzK3dcTJD60esLBlc"
      width: 1200
  id: 
  "recJGBJ9xOznB81Go"
*/

function Equipe({ base, json }) {
  const { link } = useParams();
  const navigate = useNavigate();

  const [equipes, setEquipes] = useState([]);
  const [equipeInfos, setEquipeInfos] = useState({
    titre: "",
    image: "",
    presentation: "",
    objectifs: "",
    membres: [
      {
        nom: "",
        role: "",
        lien: "",
        image: "",
      },
    ],
  });

  const [allMembers, setAllMembers] = useState([]);

  var all_members = [];

  var loaded = false;

  useEffect(() => {
    base("Equipes")
      .select({
        maxRecords: 20,
      })
      .eachPage(function page(records, fetchNextPage) {
        records.forEach(function (record) {
          if (record.get("url") === link) {
            //console.log("denom link ", record.get("denomination"), link);

            document.title = record.get("page_title") + " - Vinci Eco Drive";
            setEquipeInfos({
              titre: record.get("page_title"),
              denomination: record.get("denomination"),
              url: record.get("url"),
              image: record.get("image") ? record.get("image")[0].url : part,
              contenu: record.get("contenu" + localStorageLanguageSuffix()),
              presentation: record.get(
                "presentation" + localStorageLanguageSuffix()
              ),
              objectifs: record.get("objectif" + localStorageLanguageSuffix()),
              membres: [],
            });
          }
        });
      });

    //console.log(equipeInfos);

    base("Membres_NE_PAS_MODIFIER")
      .select({
        maxRecords: 200,
        sort: [
          { field: "Statut", direction: "desc" },
          { field: "Annee", direction: "desc" },
          { field: "Nom", direction: "asc" },
        ],
      })
      .all()
      .then((records) => {
        setAllMembers(records);
      });
  }, [base]);

  var render_members = false;

  for (let member of allMembers) {
    // add conditions here to filter members

    // to force everyone to take a nice picture
    if (member.fields.image === undefined) continue;
    // or put a stig
    if (member.fields.image === undefined) {
      member.fields.image = [
        {
          url: "",
        },
      ];
    }
    //console.log("equipe" + link);
    //if (member.fields.Statut !== "DT") continue;
    if (equipeInfos.denomination == "Bureau") {
      if (member.fields.Statut == equipeInfos.denomination) {
        all_members.push(
          <Link to={"/contact/" + member.lien}>
            <div className="member-card">
              <img
                alt={member.fields.Calculation}
                src={
                  member.fields.image[0].url !== ""
                    ? member.fields.image[0].url
                    : stig
                }
              />
              <p className="member-name">{member.fields.Calculation}</p>
            </div>
          </Link>
        );
      }
    } else {
      if (member.fields.Technical_Team == equipeInfos.denomination) {
        all_members.push(
          <Link to={"/contact/" + member.lien}>
            <div className="member-card">
              <img
                alt={member.fields.Calculation}
                src={
                  member.fields.image[0].url !== ""
                    ? member.fields.image[0].url
                    : stig
                }
              />
              <p className="member-name">{member.fields.Calculation}</p>
            </div>
          </Link>
        );
      } else if (
        member.fields.Administrative_Team.includes(equipeInfos.denomination)
      ) {
        console.log("Administrative_Team" + member.fields.Administrative_Team);
        all_members.push(
          <Link to={"/contact/" + member.lien}>
            <div className="member-card">
              <img
                alt={member.fields.Calculation}
                src={
                  member.fields.image[0].url !== ""
                    ? member.fields.image[0].url
                    : stig
                }
              />
              <p className="member-name">{member.fields.Calculation}</p>
            </div>
          </Link>
        );
      }
    }
  }
  //console.log("allMembers");
  //console.log(allMembers);
  //console.log(equipeInfos);

  return (
    <div className="Equipes">
      <div className="div-info equipe-div-img">
        <img
          className="equipe-actu-img"
          alt={equipeInfos.url}
          src={equipeInfos.image}
        ></img>
      </div>
      <div className="div-info shadow">
        <span>Presentation</span>
        <br></br>
        <h1>{equipeInfos.titre}</h1>
        <p>
          <ReactMarkdown>{equipeInfos.presentation}</ReactMarkdown>
        </p>
      </div>
      {equipeInfos.objectifs !== "" && (
        <div className="div-info">
          <span>Objectifs</span>
          <br></br>
          <h1>Objectifs</h1>
          <p>
            <ReactMarkdown>{equipeInfos.objectifs}</ReactMarkdown>
          </p>
        </div>
      )}

      <div className="div-info">
        <span>Membres</span>
        <br></br>
        <h1>Membres</h1>
        <div className="member-cards">{all_members}</div>
      </div>
    </div>
  );
}

export default Equipe;
