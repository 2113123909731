import { base } from "airtable";
import React from "react";
import { useEffect, useState } from "react";

export function GetBase(base, baseName, maxRecords, sort) {
  var allRecords = [];

  base(baseName)
    .select({
      maxRecords: maxRecords,
      sort: sort,
    })
    .all()
    .then((records) => {
      let allLength = allRecords.push(records);
      console.log("allLength", allLength);
    });
  allRecords = [3, 4];
  console.log("records", typeof allRecords);
  console.log("records", allRecords[2]);

  return allRecords;
}

export default function BaseSelect() {
  console.log("default");
  return <div>BaseSelect</div>;
}

export function localStorageLanguageSuffix() {
  let suite = "";
  if (localStorage.getItem("ved-language") !== null) {
    if (localStorage.getItem("ved-language") === "english") {
      suite = "_en";
    }
  }
  console.log("suite", suite, "|");
  return suite;
}
