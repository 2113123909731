import "./LienActu.css";
import "../../App.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkRehype from "remark-rehype";
import { Text } from "react";
import { localStorageLanguageSuffix } from "../../components/api/BaseSelect";

function LienActu({ base }) {
  window.scrollTo(0, 0);

  const [article, setArticle] = useState({
    Titre: "",
    Image: "",
    Contenu: "",
    Date: "",
    Tags: "",
  });
  const { link } = useParams();

  useEffect(() => {
    getArticle();
    // eslint-disable-next-line
  }, []);

  async function getArticle() {
    const records = await base("Articles" + localStorageLanguageSuffix())
      .select({
        filterByFormula: `SEARCH("${link}",{Url} & "")`,
      })
      .all();

    document.title = records[0].fields.Titre + " - Vinci Eco Drive";

    setArticle({
      Titre: records[0].fields.Titre,
      Image: records[0].fields.Image[0].url,
      Contenu: records[0].fields.Contenu,
      Date: records[0].fields.Date,
      Tags: records[0].fields.Tags,
      isFile: records[0].fields.Fichier === undefined ? false : true,
      Fichier:
        records[0].fields.Fichier === undefined
          ? [{ url: "" }]
          : records[0].fields.Fichier,
    });
  }

  console.log(article.isFile);
  console.log(article.Fichier);
  // console.log(article.isUrl);

  return (
    <div className="Actualite" onLoad={getArticle}>
      <div className="div-img">
        <img className="actu-img" alt={article.Titre} src={article.Image}></img>
      </div>
      <div className="div-info shadow">
        <span>{article.Tags}</span>
        <br></br>
        <h1>{article.Titre}</h1>
        <p data-aos="fade-up">
          <ReactMarkdown remarkPlugins={[remarkRehype]}>
            {article.Contenu}
          </ReactMarkdown>

          <div style={article.isFile ? null : { display: "none" }}>
            {article.Fichier === undefined ? null : (
              <a href={article.Fichier[0].url} target="_blank" rel="noreferrer">
                <button className="btn-special">Ouvrir le fichier</button>
              </a>
            )}
          </div>
          {/* 
          {article.Fichier === undefined ? null : (
            <a href={article.Fichier[0].url} target="_blank" rel="noreferrer">
              <button className="btn-special">Ouvrir le fichier</button>
            </a>
          )}
          article.Fichier === undefined ? null : article.Fichier[0].type !==
            "application/pdf" ? null : (
            <object
              data={article.Fichier[0].url}
              type="application/pdf"
              width="100%"
              height="500px"
            >
              No file - {article.Fichier[0].url}
            </object>
          )
            */}
        </p>
      </div>
    </div>
  );
}

export default LienActu;
