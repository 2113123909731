import capgemini from "../../images/partenaires/capgemini.png";
import mathworks from "../../images/partenaires/mathworks.png";
import ansys from "../../images/partenaires/ansys.png";
import coq_sportif from "../../images/partenaires/coq_sportif.png";
import dassault from "../../images/partenaires/dassault.png";
import dvic from "../../images/partenaires/dvic.png";
import enerlis from "../../images/partenaires/enerlis.png";
import hexcel from "../../images/partenaires/hexcel.png";
import sculpteo from "../../images/partenaires/sculpteo.png";
import sicomin from "../../images/partenaires/sicomin.png";
import simulia from "../../images/partenaires/simulia.png";
import skf from "../../images/partenaires/skf.png";
import rs from "../../images/partenaires/rs.png";
import xometry from "../../images/partenaires/xometry.png";
import danielson from "../../images/partenaires/danielson.png";
import easy_composite from "../../images/partenaires/easy_composite.png";

import "./Partners.css";
import { useEffect, useState } from "react";

export default function Partners({ base, json }) {
  const [partenaires, setPartenaires] = useState([]);

  var loaded = false;

  useEffect(() => {
    if (loaded === false) {
      loaded = true;
      base("Partenaires")
        .select({
          maxRecords: 50,
          sort: [{ field: "Level", direction: "desc" }],
          view: "Grid view",
        })
        .eachPage(function page(records, fetchNextPage) {
          records.forEach(function (record) {
            setPartenaires((partenaires) => [
              ...partenaires,
              {
                name: record.get("Name"),
                level: record.get("Level"),
                status: record.get("Status"),
                url: record.get("Site"),
                logo:
                  record.get("Logo") !== undefined
                    ? record.get("Logo")[0].url
                    : "",
              },
            ]);
          });
          fetchNextPage();
        });
    }
  }, [base]);

  return (
    <div class="slider">
      <div class="slide-track">
        {partenaires.map((partenaire) => (
          <a href={partenaire.url} target="_blank">
            <div className="slide">
              {/* {partenaire.name} - {partenaire.level} */}
              <img alt={partenaire.name} src={partenaire.logo} />
            </div>
          </a>
        ))}
        {/* 
        <div class="slide">
          <img src={ansys} height="100" width="270" alt="ansys" />
        </div>
        <div class="slide">
          <img src={capgemini} height="100" width="270" alt="capgemini" />
        </div>
        <div class="slide">
          <img src={mathworks} height="100" width="270" alt="mathworks" />
        </div>
        <div class="slide">
          <img src={dassault} height="100" width="280" alt="dassault" />
        </div>
        <div class="slide">
          <img src={danielson} height="100" width="270" alt="danielson" />
        </div>
        <div class="slide">
          <img src={xometry} height="100" width="280" alt="xometry" />
        </div>
        <div class="slide">
          <img
            src={easy_composite}
            height="100"
            width="270"
            alt="easy_composite"
          />
        </div>
        <div class="slide">
          <img src={sculpteo} height="100" width="280" alt="sculpteo" />
        </div>
        <div class="slide">
          <img src={hexcel} height="100" width="280" alt="hexcel" />
        </div>
        <div class="slide">
          <img src={coq_sportif} height="100" width="260" alt="coq_sportif" />
        </div>
        <div class="slide">
          <img src={sicomin} height="100" width="300" alt="sicomin" />
        </div>
        <div class="slide">
          <img src={enerlis} height="100" width="270" alt="enerlis" />
        </div>
        <div class="slide">
          <img src={simulia} height="100" width="270" alt="simulia" />
        </div>
        <div class="slide">
          <img src={dvic} height="100" width="260" alt="dvic" />
        </div>
        <div class="slide">
          <img src={skf} height="100" width="250" alt="skf" />
        </div>
        <div class="slide">
          <img src={rs} height="100" width="280" alt="rs" />
        </div>
        */}
      </div>
    </div>
  );
}
