import "./Actualites.css";
import "../home/Home.css";
import "../../App.css";
import pe from "../../images/PE_white.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";

import { GetBase } from "../../components/api/BaseSelect";

function Actualites({ base, json }) {
  document.title = "Actualités - Vinci Eco Drive";

  const navigate = useNavigate();

  const [allArticles, setAllArticles] = useState([]);
  const [allMembers, setAllMembers] = useState([]);

  var loaded = false;

  function getArticles() {
    var suite = "";
    if (localStorage.getItem("ved-language") !== null) {
      if (localStorage.getItem("ved-language") === "english") {
        suite = "_en";
      }
    }

    base("Articles" + suite)
      .select({
        maxRecords: 100,
        sort: [{ field: "Date", direction: "desc" }],
        view: "Gallery",
      })
      .all()
      .then((records) => {
        setAllArticles(records);
        /* records.forEach((record) => {
          setAllArticles((allArticles) => [...allArticles, record]);
          
        }); */
      });
    console.log("allArticles", allArticles);
  }

  function getArticlesOld() {
    if (loaded === false) {
      loaded = true; // To avoid loading the articles twice

      let div = document.getElementById("articles");

      var suite = "";

      if (localStorage.getItem("ved-language") !== null) {
        if (localStorage.getItem("ved-language") === "english") {
          suite = "_en";
        }
      }

      base("Articles" + suite)
        .select({
          maxRecords: 50,
          sort: [{ field: "Date", direction: "desc" }],
          view: "Gallery",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            // This function (`page`) will get called for each page of records.

            records.forEach(async function (record) {
              setAllArticles((allArticles) => [...allArticles, record]);
              // var url = await record.get('Image')[0].url;
              // document.getElementById('articles').innerHTML += "<div class='article' data-aos='zoom-in' onclick='window.location.href=`" + record.get('Url') + "`'>" +
              //     "<h1 style='background-image: url(" + url + "'>" + record.get('Titre') + "</h1>" +
              //     "<div class='article-ctn'>" +
              //     "<a class='a-tags'>" + record.get('Tags') + "</a>" +
              //     "<p>" + record.get('Contenu').slice(0, 61) + "...</p>" +
              //     "</div>" +
              //     "</div>";
            });

            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return;
            }
          }
        );
    }
  }

  function getMembre() {
    base("Membres_NE_PAS_MODIFIER")
      .select({
        maxRecords: 200,
        sort: [
          { field: "Statut", direction: "desc" },
          { field: "Annee", direction: "desc" },
          { field: "Nom", direction: "asc" },
        ],
      })
      .all()
      .then((records) => {
        setAllMembers(records);
      });
  }

  function handleClick(url) {
    navigate(url);
  }

  useEffect(() => {
    /* console.log("useEffect");
    setAllArticles(
      GetBase(base, "Articles", 100, [{ field: "Date", direction: "desc" }])
    ); */
    //getMembre();
    getArticles();

    console.log("allArticles", allArticles);
    // eslint-disable-next-line
  }, [base]);

  const ArticleOrLoading = () => {
    if (allArticles && allArticles.length > 0) {
      return allArticles.map((article, index) => (
        <div
          className="article"
          data-aos="zoom-in"
          key={index}
          onClick={() => handleClick(article.get("Url"))}
        >
          <div
            className="article_back"
            style={{
              backgroundImage: `url(${
                article.get("Image") ? article.get("Image")[0].url : ""
              })`,
            }}
          ></div>
          <div className="article-ctn">
            {/* eslint-disable-next-line  */}
            <a className="a-tags">{article.get("Tags")}</a>
            <p>{article.get("Titre")}</p>
          </div>
        </div>
      ));
    } else {
      return <Loading />;
    }
  };

  return (
    <div className="Actualite">
      <div className="bg relative"></div>
      <div className="div-info">
        <span>{json.actualites}</span>
        <br></br>
        <h1>{json.articles}</h1>
        <div className="div-info bg-white" id="articles">
          {ArticleOrLoading()}
        </div>
      </div>
    </div>
  );
}

export default Actualites;

/** 
<h1
  style={{
    backgroundImage: `url(${
      article.get("Image") ? article.get("Image")[0].url : ""
    })`,
  }}
>
  {article.get("Titre")}
</h1>
<div className="article-ctn">
  {/* eslint-disable-next-line 
  <a className="a-tags">{article.get("Tags")}</a>
  <p>{article.get("Contenu").slice(0, 61)}...</p>
</div>
**/
