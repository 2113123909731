import React from "react";
import "./Loading.css";

const Loading = () => {
  return (
    <>
      <p className="loading">
        <i className="fa fa-spinner fa-spin"></i>
        <div className="loading-text">
          <p>Si le chargement ne fonctionne pas, veuillez recherger la page</p>
          <p>If loading is too long, please refresh the page</p>
        </div>
      </p>
    </>
  );
};

export default Loading;
