import "./Legal.css";

function Legal_en() {
  return (
    <p>
      SIRET: 84041478300018
      <br />
      <br />
      PUBLISHER: Sunil GOULAMHOUSSEN
      <br />
      <br />
      WEBSITE PUBLISHED BY: VINCI ECO DRIVE non-profit association under French
      law (loi 1901) 12 AV LEONARD DE VINCI 92400 COURBEVOIE FRANCE, hosted on:
      Cloudflare Pages as https://www.vinciecodrive.fr,
      <br />
      <br />
      PERSONAL INFORMATION: In accordance with the Data Protection Act of
      January 6, 1978, and Regulation No. 2016/679, known as the General Data
      Protection Regulation (GDPR), you have the right to access, rectify,
      object to, and delete personal data concerning you.
      <br />
      <br />
      THE USER CAN EXERCISE THIS RIGHT BY WRITING TO: VINCI ECO DRIVE
      association at 12 AV LEONARD DE VINCI 92400 COURBEVOIE or by email at:
      contact@vinciecodrive.fr. The personal data that users may be required to
      provide is on a voluntary basis. In the event that users do not provide
      this information, VINCI ECO DRIVE would not be able to process the
      corresponding files.
      <br />
      <br />
      VIEWING: For an optimal viewing experience of the pages on this site, we
      suggest updating your web browser to enjoy the latest innovations.
      <br />
      <br />
      MINIMUM SCREEN RESOLUTION : 1024 x 768 pixels.
    </p>
  );
}

function Legal_fr() {
  return (
    <p>
      SIRET : 84041478300018
      <br />
      <br />
      DIRECTEUR DE PUBLICATION : Sunil GOULAMHOUSSEN
      <br />
      <br />
      SITE RÉALISE PAR : VINCI ECO DRIVE association loi 1901 12 AV LEONARD DE
      VINCI 92400 COURBEVOIE, hébergé par : Cloudflare Pages,
      <br />
      <br />
      INFORMATIONS PERSONNELLES : Conformément à la loi Informatique et Liberté
      du 6 janvier 1978 et au règlement n°2016/679, dit Règlement Général sur la
      Protection des Données (RGPD), vous disposez d’un droit d’accès, de
      rectification, d’opposition et de suppression sur les données à caractère
      personnel vous concernant.
      <br />
      <br />
      L'UTILISATEUR PEUT EXERCER CE DROIT EN ÉCRIVANT À : VINCI ECO DRIVE
      association loi 1901 12 AV LEONARD DE VINCI 92400 COURBEVOIE
      contact@vinciecodrive.fr Les données personnelles que les utilisateurs
      peuvent être amenés à fournir, le sont sur une base volontaire. Dans
      l'hypothèse où les utilisateurs ne fourniraient pas ces informations,
      VINCI ECO DRIVE ne serait pas en mesure de traiter les dossiers
      correspondants.
      <br />
      <br />
      VISUALISATION : Pour visualiser les pages de ce site de façon optimale,
      nous vous recommandons de mettre à jour votre navigateur web afin de
      profiter des dernières innovations.
      <br />
      <br />
      RÉSOLUTION D'ÉCRAN MINIMUM : 1024 x 768 pixels.
    </p>
  );
}

function Legal({ json }) {
  var txt_legal = "";

  if (json.langue === "fr") {
    var legal_notice_div = document.getElementById("legal_notice_div");
    //legal_notice_div.innerHTML = "Mentions légales";
  }

  return (
    <div id="Legal">
      <h1>Privacy policy</h1>
      <Legal_en />
      <br />
      <br />
      <br />
      <h1>Mentions légales</h1>
      <Legal_fr />
      <div id="legal_notice_div"></div>
    </div>
  );
}

export default Legal;
