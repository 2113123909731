import Social from "../../components/social/Social";
import pe from "../../images/PE_white.png";
import fs from "../../images/FS_white.png";
import nantes from "../../images/nantes.jpg";
import { useEffect, useState } from "react";
import "./Home.css";
import "../../App.css";
import "../actu/Actualites.css";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import Partners from "../../components/partners/Partners";

function Home({ base, json }) {
  document.title = json.titre_accueil;

  const date = new Date();
  const year = date.getFullYear();

  const navigate = useNavigate();

  const [allArticles, setAllArticles] = useState([]);

  var loaded = false;

  function getArticles() {
    document.getElementById("articles").innerHTML = "";

    if (loaded === false) {
      loaded = true; // To avoid loading the articles twice
      var suite = "";

      if (localStorage.getItem("ved-language") !== null) {
        if (localStorage.getItem("ved-language") === "english") {
          suite = "_en";
        }
      }

      base("Articles" + suite)
        .select({
          // Selecting the first 3 records in Grid view:
          maxRecords: 3,
          sort: [{ field: "Date", direction: "desc" }],
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(async function (record) {
              setAllArticles((allArticles) => [...allArticles, record]);
            });

            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return;
            }
          }
        );
    }
  }

  function handleClick(url) {
    navigate(url);
  }

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <div className="Home">
      <div className="bg">
        <div className="ctn">
          <h1>{json.slogan}</h1>
          <Link to="/actualites">
            <button className="btn-special">{json.nos_actualites}</button>
          </Link>
        </div>
      </div>
      <a href="/" class="taglink" name="introduction">
        {" "}
      </a>
      <span></span>
      <div className="div-info">
        <span>{json.introduction_caps}</span>
        <br></br>
        <h1>{json.qui_sommes_nous}</h1>
        <p data-aos="fade-up">
          {json.presentation_ved}
          <br />
          <br />
          {json.presentation_ved_paragraphe1}
          <br />
          <br />
          {json.presentation_ved_paragraphe2}
        </p>
      </div>
      <a href="/" class="taglink" name="projets">
        {" "}
      </a>
      <span></span>
      <div className="div-info">
        <span>{json.projets}</span>
        <br></br>
        <h1>{json.nos_projets}</h1>
        <div className="cards">
          <div className="card-l">
            <img src={pe} alt="logo-pe"></img>
            <h2>{json.prototype_d_efficience}</h2>
            <p>
              {json.presentation}
              <br></br>
              {json.presentation_prototype_d_efficience}
            </p>
            <p>
              {json.nos_objectifs}
              <br></br>
              {json.objectifs_prototype_d_efficience}
            </p>
            <Link to="/presentation-prototype_defficience">
              <button>{json.en_savoir_plus}</button>
            </Link>
          </div>
          <div className="card-r">
            <img src={fs} alt="logo-fs"></img>
            <h2>{json.formula_student}</h2>
            <p>
              {json.presentation}
              <br></br>
              {json.presentation_formula_student}
            </p>
            <p>
              {json.nos_objectifs}
              <br></br>
              {json.objectifs_formula_student}
            </p>
            <Link to="/presentation-formula-student">
              <button>{json.en_savoir_plus}</button>
            </Link>
          </div>
        </div>

        <div className="card-simple card-red">
          {/* <img src={fs} className="card-icon" alt="logo-nantes" /> */}
          <img src={nantes} alt="logo-nantes" />
          <div className="card-simple-ctn">
            <div className="title">{json.presentation_nantes}</div>
            {json.contenu_nantes}
          </div>
        </div>
      </div>
      <a href="/" class="taglink" name="articles">
        {" "}
      </a>
      <span></span>
      <div className="div-info">
        <span>{json.articles}</span>
        <br></br>
        <h1>{json.nos_derniers_articles}</h1>
        <div className="cards" id="articles">
          {allArticles &&
            allArticles.length > 0 &&
            allArticles.map((article, index) => (
              <div
                className="article"
                data-aos="zoom-in"
                key={index}
                onClick={() => handleClick("/actualites/" + article.get("Url"))}
              >
                <div
                  className="article_back"
                  style={{
                    backgroundImage: `url(${
                      article.get("Image") ? article.get("Image")[0].url : ""
                    })`,
                  }}
                ></div>
                <div className="article-ctn">
                  {/* eslint-disable-next-line */}
                  <a className="a-tags">{article.get("Tags")}</a>
                  <p>{article.get("Titre")}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <a href="/" class="taglink" name="competitions">
        {" "}
      </a>
      <span></span>
      <div className="div-info">
        <span>{json.competitions}</span>
        <br></br>
        <h1>{json.nos_competitions}</h1>
        <div className="compets">
          <div className="compet shell" data-aos="zoom-in">
            <div className="compet-txt">
              <div>
                <p className="tit">Shell Eco Marathon</p>
                <p className="dat">
                  {json.mai} 2024
                  <br></br>
                  <i>Nogaro, France</i>
                </p>
              </div>
            </div>
          </div>
          <div className="compet albi" data-aos="zoom-in">
            <div className="compet-txt">
              <div>
                <p className="tit">Albi Eco Race</p>
                <p className="dat">
                  {json.mai} 2024
                  <br></br>
                  <i>Albi, France</i>
                </p>
              </div>
            </div>
          </div>
          <div className="compet eco-green" data-aos="zoom-in">
            <div className="compet-txt">
              <div>
                <p className="tit">Challenge Eco Green</p>
                <p className="dat">
                  {json.mai} 2024
                  <br></br>
                  <i>Fay-de-Bretagne, France</i>
                </p>
              </div>
            </div>
          </div>
          <div className="compet fsg" data-aos="zoom-in">
            <div className="compet-txt">
              <div>
                <p className="tit">FS Germany</p>
                <p className="dat">
                  {json.objectif} {year + 1}
                  <br></br>
                  <i>Hockenheim, Allemagne</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a href="/" class="taglink" name="equipe">
        {" "}
      </a>
      <span></span>
      <div className="div-info groupe">
        <span>{json.notre_equipe}</span>
      </div>
      <a href="/" class="taglink" name="partenaires">
        {" "}
      </a>
      <span></span>
      <div className="div-info">
        <span>{json.partenaires}</span>
        <br></br>
        <h1>{json.nos_partenaires}</h1>
        <Partners base={base} />
      </div>
      <Social json={json} />
    </div>
  );
}

export default Home;
